<template>
  <form @submit="save()">
    <div class="form-group">
      <label for>Categoria</label>
      <input
        type="text"
        class="form-control"
        v-model="reg.name"
        maxlength="255"
        required
      />
    </div>
    <app-button-submit @click="save()"></app-button-submit>
  </form>
</template>

<script>
import { GeneralService } from "../GeneralService";

export default {
  data: () => ({
    reg: {}
  }),
  methods: {
    reset() {
      this.reg = {};
    },
    save() {
      GeneralService.saveCategorie(this.reg).then((res) => {
        this.$emit("submitted", res.data);
      });
    }
  }
};
</script>

<style></style>
